  
.section.work--practice--slide
{
    background-color: #F8F8F8;
}

.title--practice
{
    margin-top: 30px;
    margin-bottom: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    color: #333333;

    text-align: center;
}

.row.row--logo--company
{
    margin-bottom: 25px;
}

.company--logo
{
    max-width: 100%;
}