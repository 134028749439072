.text--semestr
{
    margin-top: 49px;
    margin-bottom: 20px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #333333;
}

.col--subject
{
    border-radius: 3px;
}


.text--subject
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 17px;

    color: #212529;

    padding-left: 9px;
    padding-right: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.row--subject
{
    padding-top: 10px;
}