.section.work--practice--slide
{
    background-color: #F8F8F8;
}

.title--work
{
    margin-top: 47px;
    margin-bottom: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-size: 1.5em;

    color: #333333;

    text-align: center;
}

.object
{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #012345;
}

.work--text
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    color: #212529;
}

@media (min-width: 768px) {
    .work--text {
        font-size: 1.5em;
        line-height: 30px;
    }

    .title--work
    {
        font-size: 3em;
    }
}
