.course--number
{
    margin-top: 49px;
    margin-bottom: 25px;
}

.item--legend
{
    width: 40px;
    height: 10px;

    background: #5470C6;
    border-radius: 5px;
}

.text--legend
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 15px;

    color: #333333;
}

.row.row--legend
{
    padding-bottom: 10px;
}



.chart {
    height: 100%;
}

.language-item {
    display: inline-block;
    margin: 1px;
    padding: 1px 4px;
    background-color: #ffc800;
    border-radius: 4px;
    box-shadow: 0 0 10px #ffc800;
}