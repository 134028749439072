.hand--icon--wrapper {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
}


.hand--icon {
    width: 50px;
    height: 50px;
}

.welcome--menu__test {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #859ADE;
    z-index: 1;
}

.pre-title {
    font-weight: 700;
    color: white;
}

.home-wrapper {
    background: linear-gradient(135deg, #012345, #00ab9b);
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 900;
    font-size: 2.5em;
    line-height: 45px;
    letter-spacing: 1px;

    color: white;
    /*text-shadow: 0 0 10px white;*/
}

.title small {
    font-weight: 700;
    font-size: 17px;
    line-height: 12px;
}

a.welcome--logo--href {
    text-decoration: none;
}


@media (min-width: 756px) {
    .title {
        font-size: 4em;
        line-height: 60px;
    }
}
