.container.container-common
{
    padding-left: 21px;
    padding-right: 21px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
}

.container.container-common.sidemenu-container {
    width: 100px;
    right: 0;
}

.sidemenu-wrapper  {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: #012345;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: -100%;
    top: 0;
        justify-content: center;

}

.sidemenu-wrapper--link {
    margin-bottom: 50px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    text-decoration: none;

    color: #FFFFFF;
    cursor: pointer;
}

.welcome--header{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-top: 49px;
}

@media (max-width: 320px) {
    .welcome--header {
        padding-bottom: 30px;
    }
}


.welcome--logo
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.15em;

    color: #FFFFFF;
}

@media (min-width: 768px) {
    .sidemenu-wrapper {
        justify-content: center;
    }
    .sidemenu-wrapper--link  {
        font-size: 3em;
    }
    .sidemenu-wrapper--link:first-child {
        padding-top: 0;
    }
}