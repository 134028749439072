.section
{
    background-color: #00ab9b;
}

.text--end
{
    padding-top: 30px;
    margin-bottom: 40px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;

    color: #FFFFFF;
}

.text-mini--btn
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    align-items: center;
    text-align: center;

    color: #00ab9b;
}


.btn.btn--end
{
    width: 240px;

    flex-direction: column;

    background: #FFFFFF;
    border-radius: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #00ab9b;
}

.btn.btn--end:hover {
    background-color: #b3fff7;
}

.btn.btn--end--two
{
    width: 240px;

    margin-top: 15px;

    flex-direction: column;

    background: #FFFFFF;
    border-radius: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #00ab9b;
}

.btn.btn--end--two:hover {
    background-color: #b3fff7;
}


.text-mini
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}

.text-mini > a {
    color: white;
    /*background-color: #012345;*/
    text-decoration: underline;
}

.photo
{
    width: 35px;
    height: 35px;
}

.row .col--photo
{
    width: 185px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.row--logo
{
    margin-bottom: 30px;
}

.logo--text
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 10px;

    color: #FFFFFF;
}

.logo
{
  width:78px;
  height:23px
}


@media (min-width: 768px) {
    .text--end {
        font-size: 2em;
    }

    .btn.btn--end {
        font-size: 1em;
    }

    .btn.btn--end--two {
        font-size: 1em;
    }
}