.section.work--info--slide
{
    background-color: #F8F8F8;
}

.title--info
{
    margin-top: 47px;
    margin-bottom: 25px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 16pt;

    color: #333333;

    text-align: center;
}

.info--text
{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18pt;
    line-height: 22pt;
    color: #212529;
    margin-bottom: 15pt;
}
@media (max-width:820.0px) {
    .title--info
    {
       
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 21px;

        color: #333333;

        text-align: center;
    }
    .info--text
    {
        font-size: 14pt;
        line-height: 15pt;
        font-weight: 400;
        margin-bottom: 12px;            
    }

}


@media (max-width:575.98px) {
    .title--info
    {
        margin-top: 10px;
        margin-bottom: 15px;

     
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 21px;

        color: #333333;

        text-align: center;
    }
    .info--text
    {
        font-size: 11.5pt;
        line-height: 15.5pt;
        font-weight: 400;
        margin-bottom: 5px;            
    }

}

